.banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #D1D1D1;
  background: #FFF;
  padding: 16px 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  div {
    a {
      color: #212121;
      font-weight: 500;

      &:hover {
        color: #ff6b30;
      }
    }
  }
}

.button {
  flex-shrink: 0;
  width: 90px;
}

@media (max-width: 768px) {
  .banner {
    flex-direction: column;
    padding: 16px 5%;
  }

  .button {
    width: 100%;
  }
}
