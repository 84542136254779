@import "assets/styles/reset.scss";
@import "assets/styles/vars";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

@font-face {
  font-family: 'Srbija Sans';
  src: url('./assets/fonts/SrbijaSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

html {
  background: #fff;
  scroll-behavior: smooth;
}

body {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
