.StatsBlock {
  background: #fff;
  padding: 48px 0;
}

.container {
  width: 82%;
  margin: 0 auto;
  display: flex;
  gap: 24px;
  justify-content: center;
}

.item {
  display: flex;
  padding: 24px;
  align-items: center;
  gap: 16px;
  background: #F8F8F8;
  border-radius: 12px;

  img {
    width: 72px;
    height: 72px;
    flex-shrink: 0;
  }

  p {
    color: #212121;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
  }
}

@media (max-width: 1024px) {
  .container {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .StatsBlock {
    padding: 40px 0;
  }

  .container {
    width: 90%;
  }

  .item {
    padding: 16px;

    p {
      font-size: 16px;
    }
  }
}
