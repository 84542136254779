.CategoriesBlock {
  background: #fff;
}

.container {
  width: 82%;
  padding: 40px 0;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.topText {
  color: #FF6B30;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.title {
  color: #212121;
  text-align: center;
  font-family: 'Srbija Sans', sans-serif;
  font-size: 36px;
  line-height: 110%;
}

.catalog {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 24px;
  width: 100%;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  gap: 16px;
  border-radius: 12px;
  border: 1px solid #F8F8F8;
  background: #F8F8F8;

  h4 {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #212121;
    text-align: right;
  }

  .image {

    img {
      filter: grayscale(1);
      width: 40px;
    }
  }

  &:hover {
    border: 1px solid #FF6B30;
  }
}

@media (max-width: 1024px) {
  .catalog {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 760px) {
  .container {
    width: 90%;
    gap: 24px;
    padding: 24px 0;
  }

  .catalog {
    grid-template-columns: repeat(2, 1fr);
  }

  .title {
    font-size: 36px;
  }
}

@media (max-width: 550px) {
  .catalog {
    grid-template-columns: repeat(1, 1fr);
  }
}
