.TopBlock {
  background: #F8F8F8;
}

.container {
  padding: 40px 0;
  margin: 0 auto;
  width: 82%;
  display: flex;
  justify-content: space-between;


  .left {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 32px;
  }

  .right {
    padding: 0 40px;
    margin: auto;

    img {
      max-height: 620px;
    }
  }
}

.title {
  color: #212121;
  font-family: 'Srbija Sans', sans-serif;
  font-size: 48px;
  line-height: 117%;
}

.text {
  color: #6C6E79;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%
}

.info {
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
}
.infoItem {
  display: flex;
  flex-direction: column;
  gap: 16px;

  h4 {
    color: #FF6B30;
    font-family: 'Srbija Sans', sans-serif;
    font-size: 24px;
    line-height: 117%;
  }

  p {
    color: #212121;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
  }
}


@media (max-width: 1024px) {
  .container {
    .left {
      width: 60%;
    }

    .right {
      padding: 0 20px;
    }
  }

  .title {
    font-size: 40px;
  }
}

@media (max-width: 825px) {
  .container {
    flex-direction: column;
    gap: 24px;

    .left {
      width: 100%;
      gap: 16px;
    }

    .right {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 0;
    }
  }

  .info {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .container {
    width: 90%;
  }

  .title {
    font-size: 32px;
  }


}
