.SearchCatalog {
  width: 100%;

  form {
    position: relative;

    .searchIcon {
      position: absolute;
      left: 12px;
      top: 12px;
    }
  }
}

.input {
  height: 40px;
  width: 100%;
  padding: 0 12px 0 36px;
  border-radius: 6px;
  border: 1px solid #C4C7CF;
  background: #FFF;
  font-size: 16px;

  &:focus {
    border: 1px solid #008FF8;
    box-shadow: 0 0 0 3px rgba(0, 143, 248, 0.20);
  }
}

.searchResult {
  position: absolute;
  left: 0;
  right: 0;
  max-height: 192px;
  overflow: hidden;
  background: #fff;
  -webkit-box-shadow: 0 10px 10px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 10px 10px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 10px 2px rgba(0, 0, 0, 0.1);
}

.lineCatalog {
  margin-top: 8px;
  display: flex;
  gap: 12px;
}

@media (max-width: 768px) {
  .SearchCatalog {
    width: 100%;
  }
}
