.AboutUs {
  background: #fff;

}

.container {
  width: 82%;
  margin: 0 auto;
  padding: 80px 0;

  display: flex;
  gap: 45px;
}

.content {
  display: flex;
  padding: 40px;
  flex-direction: column;
  gap: 24px;
  border-radius: 24px;
  background: #F8F8F8;
  width: 60%;
  order: 1;
}

.line {
  border-bottom: 1px solid #D1D1D1;
}

.item {
}

.button {
  cursor: pointer;
  width: 100%;
  text-align: left;
  color: #212121;
  font-size: 20px;
  font-weight: 500;
  line-height: 120%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.active {
  img {
    transform: rotate(180deg);
  }
}


.text {
  margin-top: 16px;
  color: #6C6E79;
  font-size: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  line-height: 24px;

}

.title {
  display: flex;
  flex-direction: column;
  gap: 24px;
  order: 2;

  div {
    color: #FF6B30;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  h2 {
    color: #212121;
    font-family: 'Srbija Sans', sans-serif;
    font-size: 36px;
    line-height: 110%;
  }

  img {

  }
}

@media (max-width: 825px) {
  .container {
    flex-direction: column;
  }

  .content {
    width: 100%;
    order: 3;
  }

  .title {
    text-align: center;

    img {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  .container {
    width: 90%;
    gap: 32px;
    padding: 48px 0;
  }

  .content {
    padding: 16px;
    gap: 16px;
  }

  .button {
    font-size: 16px;
  }


}
