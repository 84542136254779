.Description {


  background: #fff;


}

.container {
  width: 82%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 80px 0;
}

.content {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  order: 2;

  .topText {
    color: #FF6B30;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  h2 {
    color: #212121;
    font-family: 'Srbija Sans', sans-serif;
    font-size: 36px;
    line-height: 110%;
    text-align: left;
  }

  p {
    color: #6C6E79;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
}

.image {
  order: 1;
  width: 50%;

  img {
    max-height: 500px;
  }
}

.capabilities {
  display: flex;
  flex-direction: column;
  gap: 24px;

  div {
    display: flex;
    align-items: center;
    gap: 16px;

    img {
      width: 28px;
      height: 28px;
      flex-shrink: 0;
    }

    p {
      color: #212121;
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
    }
  }
}


@media (max-width: 1024px) {
  .content {
    width: 60%;
  }
  .image {
    width: 40%;
  }
}

@media (max-width: 825px) {
  .container {
    width: 90%;
    flex-direction: column;
    gap: 40px;
    padding: 48px 0;
  }

  .content {
    width: 100%;
    text-align: center;

    h2 {
      font-size: 32px;
    }
  }
  .image {
    display: block;
    order: 3;
    width: 100%;

    img {
      margin: auto;
    }
  }
}

@media (max-width: 768px) {

}
