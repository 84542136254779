.CatalogBlock {
  background: #fff;
}

.container {
  width: 82%;
  padding: 64px 0;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.topText {
  color: #FF6B30;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.title {
  color: #212121;
  text-align: center;
  font-family: 'Srbija Sans', sans-serif;
  font-size: 36px;
  line-height: 110%;
}

.greyCatalog {
  display: flex;
  padding: 40px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  border-radius: 24px;
  background: #F8F8F8;
  width: 100%;
}

.catalog {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
  width: 100%;
}

.item {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  background: #fff;
  padding: 16px;
  color: #212121;
  text-align: center;
  border: 1px solid #fff;

  .image {
    display: flex;
    height: 56px;

    img {
      margin: auto;
      max-height: 54px;
      filter: grayscale(1);
    }
  }

  &:hover {
    border: 1px solid #FF6B30;
  }

}

@media (max-width: 1024px) {
  .catalog {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .container {
    width: 90%;
    gap: 24px;
    padding: 24px 0;
  }
}

@media (max-width: 600px) {
  .catalog {
    grid-template-columns: repeat(1, 1fr);
  }

  .greyCatalog {
    padding: 24px;
  }
}

