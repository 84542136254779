:root {
  --main-font: "Srbija Sans", sans-serif;
  --secind-font: "Roboto", sans-serif;

  //Colors
  --primary: #212121;
  --secondary: #212121;
  --secondary-grey: #6C6E79;

  --form3-card-radius: 16px;
  --form3-card-background: #E8E8E7;

  --primary-color: #ff6b30;
  --button-hover: #C74500;
  --button-active: #9F3500;

  --border-radius-btn: 12px;

}